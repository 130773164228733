import React, { useContext/*, useState, useMemo*/ } from 'react';
import styled from '@emotion/styled';
import { Button } from '@xchange/uikit';
import EditorLayout from 'components/EditorLayout';
import PDFViewer from './PDFViewer';
import SignHeader from './SignHeader';
import SignContext from './context';
// import { Node } from './types';



/*const isInViewport = (element: HTMLElement | null) => {
  if (!element || !window) return false;
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || window.document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || window.document.documentElement.clientWidth)
  );
};*/

const PDFDocument = () => {
  const {
    document,
    signedNodes,
    signatures,
    signDocument,
    signatureInfo,
    loadingNextDocument,
    //documentDispatch
  } = useContext(SignContext);
  const showNextSignature = signedNodes.length < signatures.length;

  /*const [currentSelection, setCurrentSelection] = useState<number>();
  const unsignedNodes = signatures.filter(signature => !signedNodes.includes(signature.id)).map(node => node.id);
  const { currentPage } = document;

  const disableNextSignatureButton = useMemo(() => {
    if (!currentSelection) return false;
    const nextSelection = unsignedNodes[0];
    const nodeToScroll = window?.document.getElementById('' + nextSelection);

    if (isInViewport(nodeToScroll)) {
      return true;
    }
    return unsignedNodes.includes(currentSelection);
  }, [currentSelection, unsignedNodes]);

  const getNextUnsignedNode = (arr: Node[]) => {
    if (!arr.length) return;
    const match: Node = arr.shift()!;

    if (signedNodes.includes(match.id)) {
      return getNextUnsignedNode(arr);
    }
    return match;
  };*/

  /*const handleNextSignatureClick = () => {
    const delimiter = signatures.findIndex(signature => signature.id === currentSelection) + 1;
    const searchInArray = signatures.slice(delimiter);
    const targetNode: Node = getNextUnsignedNode(!searchInArray.length ? signatures : searchInArray);
    
    setCurrentSelection(targetNode.id);
    if (currentPage !== targetNode.page) {
      documentDispatch({ type: 'SET_CURRENT_PAGE', value: targetNode.page });
    }
    if (window.innerWidth > 767) {
      documentDispatch({ type: 'SET_NODE_TO_SCROLL', value: targetNode.id });
    }
  };*/

  const stickyHeader = document.loaded ? (
    <div className="sign-count">
      <div>
        Signatures Signed: {signedNodes.length}/{signatures?.length}
      </div>
        {(
          signatureInfo?.nextDocument !== null ?
          (
            <Button disabled={showNextSignature || loadingNextDocument} onClick={signDocument}>
              Sign Next Document
            </Button>
          ):(
            <Button disabled={showNextSignature || loadingNextDocument} onClick={signDocument}>
              Submit Signatures
            </Button>
          )
        )}
    </div>
  ) : null;

  return (
    <StyledEditorLayout
      content={<PDFViewer />}
      header={<SignHeader />}
      stickyHeader={stickyHeader}
    />
  );
};

export default PDFDocument;

const StyledEditorLayout = styled(EditorLayout)`
  header {
    justify-content: center;

    .logo-container {
      width: 150px;
    }

    .document-navigation {
      margin: auto;

      .button {
        margin: 0 10px;
        padding: 3px;

        &:nth-of-type(2) .icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  .content {
    margin: 0 auto;
    max-width: 880px;
    height: 100%;
  }

  .sign-count {
    padding: 8px 27px 8px 33px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 8px;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    background-color: #F8F8F8;

    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      padding: 8px 10px;
    }
  }
`;

import { Node } from './types.d';

export type DocumentReducerAction =
  | { type: 'SET_NODES'; value: Node[] }
  | { type: 'SET_DOCUMENT_URL'; value: string }
  | { type: 'SET_DOCUMENT_NAME'; value: string }
  | { type: 'SET_TOTAL_PAGES'; value: number }
  | { type: 'SET_DOCUMENT_LOADED'; value: boolean }
  | { type: 'SET_CURRENT_PAGE'; value: number }
  | { type: 'SET_CURRENT_PAGE_SIZE'; value: { width: number; height: number } }
  | { type: 'SIGN_NODE'; value: Node }
  | { type: 'SET_SIGNATURES'; value: number }
  | { type: 'SET_NODE_TO_SCROLL'; value?: number };

export type DocumentReducerState = {
  url?: string;
  name?: string;
  nodes: Node[];
  totalPages?: number;
  loaded: boolean;
  currentPage: number;
  currentPageSize?: { width: number; height: number };
  nodeToScroll?: number;
};

export const documentReducer = (
  state: DocumentReducerState,
  action: DocumentReducerAction
): DocumentReducerState => {
  switch (action.type) {
    case 'SET_NODES':
      return { ...state, nodes: action.value };
    case 'SIGN_NODE':
      return {
        ...state,
        nodes: state.nodes.map(item => (item.id === action.value.id ? action.value : item))
      };
    case 'SET_DOCUMENT_URL':
      return { ...state, url: action.value };
    case 'SET_DOCUMENT_NAME':
      return { ...state, name: action.value };
    case 'SET_TOTAL_PAGES':
      return { ...state, totalPages: action.value };
    case 'SET_DOCUMENT_LOADED':
      return { ...state, loaded: action.value };
    case 'SET_CURRENT_PAGE':
      return { ...state, currentPage: action.value };
    case 'SET_CURRENT_PAGE_SIZE':
      return { ...state, currentPageSize: action.value };
    case 'SET_NODE_TO_SCROLL':
      return { ...state, nodeToScroll: action.value };
    default:
      return state;
  }
};

export default documentReducer;

import React, { useContext } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import signatureImage from 'assets/images/sign_bg.png';
import initialImage from 'assets/images/initial_bg.png';
import SignContext from './context';
import { EditableNode } from 'views/PDFEditor/types';

interface TextNodeComponentProps {
  data: EditableNode;
}

const TextNodeComponent: React.FC<TextNodeComponentProps> = ({ data }) => {
  const { signedNodes, signatureInfo, submitSignature } = useContext(SignContext);
  const { type } = data;
  const isSigned = signedNodes.includes(data.id);
  const image = type === 'signature' ? signatureImage : initialImage;
  const shouldBeSigned =
    data.role === signatureInfo?.role && (type === 'signature' || type === 'initials');

  const getContent = () => {
    if (shouldBeSigned) {
      return (
        <StyledSignComponent
          id={data.id}
          className={clsx('text-node', type, { signed: isSigned })}
          style={{ left: data.position.x, top: data.position.y }}
          onClick={() => submitSignature(data.id)}>
          {isSigned ? data.text : <img src={image} alt={data.text} />}
        </StyledSignComponent>
      );
    }
    return (
      <StyledSignComponent
        id={data.id}
        className={clsx("text-node", type)}
        style={{ left: data.position.x, top: data.position.y }}>
        {data.text}
      </StyledSignComponent>
    );
  };

  return getContent();
};

export default TextNodeComponent;

const StyledSignComponent = styled.div`
  display: inline-flex;
  align-items: center;
  position: absolute;
  z-index: 1000;
  &.signature,
  &.initials {
    cursor: pointer;
    font-family: 'Allura';
    font-size: 12px;
  }
  &.signed {
    padding: 4px;
    background-color: rgb(254, 251, 174);
    font-size: 20px;
    font-weight: bold;
    color: ${props => props.theme.colors.grayDark};
    pointer-events: none;
    background-image: none;
  }
  &.strikethrough {
    height: 20px;
    min-width: 30px;
    align-items: center;
    border: 1px dashed ${props => props.theme.colors.seashell};
    overflow: auto;

    hr {
      margin: 0;
      width: 100%;
      border-width: 0 0 2px 0;
      border-color: ${props => props.theme.colors.red};
    }
  }
`;

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { Button, HeaderOnlyLayout, Input } from 'components';
import { SignInfo } from './types.d';

interface SignInfoFormProps {
  onSubmit: (values: SignInfo) => void;
  data?: { signature?: string; initials?: string };
}

const SignInfoForm: React.FC<SignInfoFormProps> = ({ onSubmit, data }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<SignInfo>({
    defaultValues: data
  });

  useEffect(() => {
    if (data) reset(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <StyledHeaderOnlyLayout>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <p className="description">To begin signing please confirm the following information</p>
        <Input
          {...register('signature', { required: 'Required' })}
          label="Full Name"
          placeholder="Enter"
          error={errors.signature?.message}
        />
        <Input
          {...register('initials', { required: 'Required' })}
          label="Initials"
          placeholder="Enter"
          error={errors.initials?.message}
        />
        <Button>Begin Signing</Button>
        <p className="description">
          Please note that by signing you are legally bound to this contract.
        </p>
      </StyledForm>
    </StyledHeaderOnlyLayout>
  );
};

export default SignInfoForm;

const StyledHeaderOnlyLayout = styled(HeaderOnlyLayout)`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  header {
    padding: 0 20px;
    justify-content: flex-start;
  }

  .content {
    display: flex;
    justify-content: center;
    padding: 20px;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 440px;
  align-items: center;

  .description {
    text-align: center;
    white-space: nowrap;
    font-size: 12px;
  }

  .input,
  .button {
    width: 100%;
    margin-bottom: 10px;
  }

  .button {
    margin-top: 10px;
    height: 40px;
  }
`;

import React, { useContext } from 'react';
import clsx from 'clsx';
import styled from '@emotion/styled';
import { Icon } from 'components';
import SignInfoForm from './SignInfoForm';
import SignContext, { SignContextWrapper } from './context';
import PDFDocument from './PDFDocument';

interface MessageProps {
  children: React.ReactNode;
  type?: 'success' | 'error';
}

const Message: React.FC<MessageProps> = ({ children, type }) => {
  const iconName = type === 'error' ? 'close' : 'check';
  return (
    <StyledMessage className={clsx('message', type)}>
      <div className="circle">
        <Icon name={iconName} />
      </div>
      {children}
    </StyledMessage>
  );
};

const errorMessage = (
  <Message type="error">
    This link is expired or broken.
  </Message>
);

const SignPDF = () => {
  const { signatureInfo, setSignatureInfo, setDocumentId, error, completedSignInfoScreen, setCompletedSignInfoScreen, setSignInfoScreenValues } = useContext(
    SignContext
  );

  const handleSubmit = formValues => {
    setCompletedSignInfoScreen(true);
    setSignInfoScreenValues(formValues);
    setSignatureInfo(v => ({ ...v, ...formValues }));
    setDocumentId(signatureInfo?.documentId);
  };

  if (error) return errorMessage;

  const getContent = () => {
    if (signatureInfo?.signed) {
      if (signatureInfo.role === 'CompanyManager') {
        return (
          <Message>
            The invite to sign the contract has been sent to the user. <br /> Once signed, new user will be able to log in to the system. 
          </Message>
        );
      }
      return (
        <Message>
          The document has been signed. <br /> You'll receive the signed copy once all parties will sign the document. <br /> You can close the window now.
        </Message>
      );
    }

    if(!completedSignInfoScreen) {
      return (
        <SignInfoForm
          onSubmit={handleSubmit}
          data={{
            signature: signatureInfo?.signature,
            initials: signatureInfo?.initials
          }}
        />
      );
    }else{
      return (
        <PDFDocument />
      )
    }
  };

  return signatureInfo ? getContent() : null;
};

export default props => (
  <SignContextWrapper>
    <SignPDF {...props} />
  </SignContextWrapper>
);

const StyledMessage = styled.div`
  margin: 150px auto;
  max-width: 600px;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 34px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.red};
    .icon {
      fill: ${props => props.theme.colors.red};
    }
  }
  &.success {
    .circle {
      border: 1px solid ${props => props.theme.colors.green};
    }
    .icon {
      fill: ${props => props.theme.colors.green};
    }
  }
  &.error {
    .circle {
      border: 1px solid ${props => props.theme.colors.red};
    }
    .icon {
      fill: ${props => props.theme.colors.red};
    }
  }
  a {
    margin-top: 10px;
    display: block;
    color: ${props => props.theme.colors.red};
    text-decoration: none;
  }
`;

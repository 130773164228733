import React from 'react';
import styled from '@emotion/styled';
import DocumentNavigation from './DocumentNavigation';

const SignHeader = () => (
  <StyledHeader className="sign-header">
    <DocumentNavigation />
  </StyledHeader>
);

export default SignHeader;

const StyledHeader = styled.div`
  margin: 0 16px 0 30px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  .sign-header {
    &__name {
      display: inline-flex;
      font-size: 12px;
      line-height: 16px;
      margin-right: auto;
    }
  }
`;

import React, { useContext, useMemo, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { pdfjs, Document, Page } from 'react-pdf';
import styled from '@emotion/styled';
import SignContext from './context';
import TextNodeComponent from './TextNodeComponent';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface PDFViewerProps {
  test?: any;
}

const Pointer = ({ active, position, ...props }) => (
  <StyledPointer
    className={clsx('pointer', { active })}
    style={{ ...position }}
    {...props}
  >
    <svg width="29" height="15" viewBox="0 0 29 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H20.5L29 7.5L20.5 15H0V0Z" />
    </svg>
  </StyledPointer>
);

const PDFViewer: React.FC<PDFViewerProps> = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {
    document,
    signatures,
    signedNodes,
    documentDispatch
  } = useContext(SignContext);
  const { currentPage, nodeToScroll } = document;

  const signaturePointers = useMemo(() => signatures
    .filter(signature => signature.page === currentPage)
    .map(item => (
      <Pointer
        key={item.id}
        active={!signedNodes.includes(item.id)}
        position={{ top: item.position.y }}
      />
    )), [signatures, currentPage]);

  useEffect(() => {
    const target = window?.document.getElementById('' + nodeToScroll);
    if (target) target.scrollIntoView({ behavior: 'smooth' });
  }, [nodeToScroll]);

  return (
    <StyledPDFViewer
      ref={wrapperRef}
      className="pdf-viewer"
    >
      {document.loaded &&
        document.nodes.filter(node => node.page === document.currentPage).map(node => <TextNodeComponent key={node.id} data={node} />)}
      <Document
        file={document.url}
        onLoadSuccess={pdf => {
          documentDispatch({ type: 'SET_DOCUMENT_LOADED', value: true });
          documentDispatch({ type: 'SET_TOTAL_PAGES', value: pdf.numPages });
        }}>
        <Page
          pageNumber={document.currentPage}
          width={820}
          onLoadSuccess={page =>
            documentDispatch({ type: 'SET_CURRENT_PAGE_SIZE', value: { width: page.originalWidth, height: page.originalWidth } })
          }
        />
      </Document>
      {signaturePointers}
    </StyledPDFViewer>
  );
};

export default PDFViewer;

const StyledPDFViewer = styled.div`
  border: 1px solid #00000050;
  position: relative;
  .react-pdf__Page {
    overflow: hidden;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: min-content;
    .react-pdf__Page {
      overflow: visible;
    }
  }
`;

const StyledPointer = styled.div`
  position: absolute;
  margin-top: 7px;
  left: -30px;
  svg {
    fill: ${props => props.theme.colors.lightGray};
  }
  &.active {
    svg {
      fill: ${props => props.theme.colors.red};
    }
  }
`;
